import * as React from 'react';
import { Component } from 'react';
import { Link } from 'gatsby';

export default class Package extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { data, item, isSDTR } = this.props;
    const productTitle = isSDTR ? 'BOOK NOW' : 'START FREE TRIAL';

    return (
      <div
        className={`fx-package ${item.includes('--active') ? 'fx-package--active' : ''} ${item.includes('--inactive') ? 'fx-package--inactive' : ''
          } fx-layout-col-mobile--24 fx-layout-col-tablet--12 fx-layout-col-laptop--8`}
      >
        <div
          className={`fx-package-content fx-l-rich-text fx-package-content--card fx-package-content--shadow`}
        >
          <div className="fx-package-header">{data.title.text}</div>
          {data.subtitle.html && (
            <div className="fx-package-subtitle" dangerouslySetInnerHTML={{ __html: data.subtitle.html }} />
          )}

          {data.body.html && (
            <div className="fx-package-body" dangerouslySetInnerHTML={{ __html: data.body.html }} />
          )
          }
          {data?.cta_title?.text && data.cta_is_external ? (
            <Link
              id={`${process.env.TENANT}-${item}`}
              className="fx-l-section-cta fx-btn fx-btn--primary-outlined"
              to={data.cta_link.text}
            >
              {data.cta_title.text}
            </Link>
          ) : (
            <Link
              id={`${process.env.TENANT}-${item}`}
              className="fx-btn fx-btn--primary-outlined"
              type="button"
              data-package={item}
              to={'/book-now'}
            >
              {productTitle}
            </Link>
          )}
        </div>
      </div>
    );
  }
}
