import { graphql } from 'gatsby';
import * as React from 'react';
import { CTAButton } from '../components/shared/layout/cta';
import { Helmet } from 'react-helmet';
import ReactSlider from 'react-slider';
import styled from 'styled-components';
import FxLayout from '../components/shared/layout/layout';
import uiService from '../components/shared/ui-service';
import Package from '../components/package';
import { mergePreviewData } from '../services/merge-preview-data';

// TODO the following styled components are a side affect of a npm library and should be moved out of here
/* tslint:disable-next-line:variable-name */
const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 25px;
`;

/* tslint:disable-next-line:variable-name */
const StyledThumb = styled.div`
  position: relative;
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  cursor: grab;
  border-radius: 50%;
  background: rgba(223, 0, 116, 1);
  outline: 0;
`;

/* tslint:disable-next-line:variable-name */
const StyledThumbValue = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 150px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
  cursor: grab;
`;

/* tslint:disable-next-line:variable-name */
const Thumb = (props: any, state: any) => {
  return (
    <StyledThumb {...props}>
      <StyledThumbValue>
        {state.valueNow < 250000
          ? `$${Math.floor(250000)}`
          : state.valueNow > 1000000
          ? `$${Math.floor(1000000)}`
          : `$${state.valueNow}`}
      </StyledThumbValue>
    </StyledThumb>
  );
};

/* tslint:disable-next-line:variable-name */
const StyledTrack = styled.div`
  height: 5px;
  top: 10px;
  bottom: 0;
  background: ${(props: any) => (props.index === 0 ? '#df0074' : props.index === 1 ? '#ddd' : '#df0074')};
  border-radius: 999px;
`;

/* tslint:disable-next-line:variable-name */
const Track = (props: any, state: any) => <StyledTrack {...props} index={state.index} />;

export const query = graphql`
  query {
    site {
      siteMetadata {
        name
        tagline
        siteUrl
      }
    }
  }
`;

export default class IndexPage extends React.Component<any, any> {
  uiService = uiService;
  invalidPackage = false;
  constructor(props: any) {
    super(props);

    this.state = {
      packageRange: [],
      selectedPackage: -1,
      collectionLayout: true,
      defaultValue: 249000,
      accordionToggleIndex: null,
      previewPageContext: undefined,
    };

    if (typeof window !== 'undefined') {
      this.invalidPackage = new URLSearchParams(window.location.search).get('invalid-package') === 'true';
    }

    this.uiService.headerInverted = true;
    this.uiService.headerStickyClass = 'fx-l-header--sticky fx-l-header--dark';
    this.resetPackage = this.resetPackage.bind(this);
    this.setPackage = this.setPackage.bind(this);
    this.setCollectionLayout = this.setCollectionLayout.bind(this);
    this.toggleAccordion = this.toggleAccordion.bind(this);
  }

  setCollectionLayout() {
    this.setState({ collectionLayout: !this.state.collectionLayout });
  }

  resetPackage() {
    const newState = { packageRange: [], selectedPackage: -1, collectionLayout: true, defaultValue: 249000 };
    this.setState({
      ...newState,
    });
  }

  setPackage(event: any) {
    if (event < 250000) {
      this.setState({ selectedPackage: 0 });
    }
    if (event >= 250000 && event < 500000) {
      this.setState({ selectedPackage: 1 });
    }
    if (event >= 500000 && event < 1000000) {
      this.setState({ selectedPackage: 2 });
    }
    if (event > 1000000) {
      this.setState({ selectedPackage: 3 });
    }
  }

  toggleAccordion(event: any) {
    event.preventDefault();

    if (this.state.accordionToggleIndex === event.currentTarget.dataset.id) {
      this.setState({ accordionToggleIndex: null });
      return;
    }
    this.setState({ accordionToggleIndex: event.currentTarget.dataset.id });
  }

  componentDidMount() {
    const IS_BROWSER = typeof window !== 'undefined';
    const staticData = this.props.pageContext;
    if (IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__) {
      this.setState({ previewPageContext: mergePreviewData(staticData, window.__PRISMIC_PREVIEW_DATA__) });
    }
  }

  render() {
    const pageContext = this.state.previewPageContext || this.props.pageContext;
    const seoMeta = pageContext.seoMeta && pageContext.seoMeta.primary;
    const contentPanels = pageContext.contentPanels || [];
    const isSDTR = process.env.TENANT === 'sdtr';
    const isVisory = process.env.TENANT === 'sigma';

    let pageBG;

    if (pageContext.page && pageContext.page.data.background_image) {
      pageBG = {
        backgroundImage: `url(${pageContext.page.data.background_image.url || ''})`,
      };
    }

    return (
      <FxLayout sitemap={pageContext.sitemap}>
        {
          <Helmet>
            <title>{seoMeta && seoMeta.seo_title.text}</title>
            <meta name="keywords" content={seoMeta && seoMeta.seo_keywords.text} />
            <meta name="description" content={seoMeta && seoMeta.seo_description.text} />
            <link
              type="canonical"
              href={`${this.props.data.site.siteMetadata.siteUrl}${pageContext.page && pageContext.page.url}`}
            />
          </Helmet>
        }
        <div
          className="fx-l-product"
          style={{
            backgroundImage: pageBG && pageBG.backgroundImage ? pageBG.backgroundImage : '',
          }}
        >
          {this.invalidPackage && isSDTR && (
            <div className="fx-toast fx-toast--secondary fx-toast--centered">
              To register with {process.env.SITE_TITLE}, please select an appropriate package below
            </div>
          )}

          {contentPanels.map((panel: any) => {
            if (panel.type === 'component') {
              if (isVisory) {
                return null;
              }
              return (
                <section className="fx-l-packages" key={panel.id}>
                  <div className="fx-l-packages-content">
                    <div className="fx-container">
                      <div className="fx-layout">
                        {panel.items.map((item: any, count: number) => {
                          const data = item.node.data;

                          return (
                            <Package
                              key={count}
                              count={count}
                              data={data}
                              item={item.node.uid}
                              isSDTR={isSDTR}
                              isVisory={isVisory}
                              selectedPackage={this.state.selectedPackage}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </section>
              );
            }

            if (panel.type !== 'collection') {
              return panel.items.map((item: any) => {
                if (item) {
                  const templates = item.content.document.data.templates;
                  const data = item.content.document.data;
                  if (templates) {
                    if (
                      templates.find((template: any) => {
                        return template.template && template.template.uid.includes('grid');
                      })
                    ) {
                      return (
                        <section
                          className="fx-l-section fx-l-grid"
                          key={`${new Date().getMilliseconds()}-${Math.random()}`}
                        >
                          <div className="fx-container">
                            <div className="fx-layout">
                              <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--12 fx-layout-col-laptop--6">
                                {data.title.text}
                              </div>
                            </div>
                          </div>
                        </section>
                      );
                    }
                    if (
                      templates.find((template: any) => {
                        return template.template && template.template.uid === 'fx-l-hero-unit';
                      })
                    ) {
                      return (
                        <div className={`fx-l-hero`} key={`${new Date().getMilliseconds()}-${Math.random()}`}>
                          <div className="fx-l-hero-content">
                            <div className="fx-container">
                              <h1 className="fx-l-hero-title">{data.title.text}</h1>
                              <h2 className="fx-l-hero-subtitle">{data.body.text}</h2>
                              <CTAButton data={data} />
                            </div>
                          </div>
                        </div>
                      );
                    }

                    if (
                      templates.find((template: any) => {
                        return template.template && template.template.uid.includes('fx-l-section--split');
                      })
                    ) {
                      return (
                        <section
                          className={`fx-l-section ${templates
                            .map((template: any) => `${template.template && template.template.uid}`)
                            .join()
                            .replace(',', ' ')}`}
                          key={`${new Date().getMilliseconds()}-${Math.random()}`}
                        >
                          <div className="fx-l-section-content">
                            <div className="fx-container">
                              <div className="fx-layout">
                                <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--12">
                                  {data.images.find((image: any) => image.image.url) &&
                                    data.images.map((image: any) => (
                                      <img
                                        className="fx-l-section-media"
                                        key={`image--${new Date().getMilliseconds()}-${Math.random()}`}
                                        src={image.image.url}
                                        alt={image.image.alt}
                                      />
                                    ))}
                                </div>
                                <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--12">
                                  <h3 className="fx-l-section-title">{data.title.text}</h3>
                                  <p className="fx-l-section-subtitle">{data.subtitle.text}</p>
                                  <p className="fx-l-section-body">{data.body.text}</p>
                                  <CTAButton data={data} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      );
                    } else {
                      let sectionBgImage;

                      if (data.background_image && data.background_image.url) {
                        sectionBgImage = {
                          backgroundImage: `url(${data.background_image && data.background_image.url})`,
                          backgroundSize: 'cover',
                        };
                      }

                      return (
                        <section
                          className={`fx-l-section ${templates
                            .map((template: any) => `${template.template && template.template.uid}`)
                            .join()
                            .replace(',', ' ')}`}
                          key={`${new Date().getMilliseconds()}-${Math.random()}`}
                          style={sectionBgImage}
                        >
                          <div className="fx-l-section-content">
                            <div className="fx-container">
                              <h3 className="fx-l-section-title">{data.title.text}</h3>
                              {data.body.text && <p className="fx-l-section-body">{data.body.text}</p>}
                              <CTAButton data={data} />
                            </div>
                          </div>
                        </section>
                      );
                    }
                  } else {
                    return null;
                  }
                }
                return null;
              });
            } else {
              return (
                <div
                  id="fx-l-faq"
                  className={`fx-l-collection  
                ${
                  panel &&
                  panel.items &&
                  panel.items.slice(0, 1).map((item: any) => {
                    return item.templates
                      ? item.templates.map((template: any) =>
                          template.template && template.template.uid.includes('--secondary')
                            ? ' fx-l-section--secondary'
                            : ''
                        )
                      : item.data.templates.map((template: any) =>
                          template.template && template.template.uid.includes('--secondary')
                            ? ' fx-l-section--secondary'
                            : ''
                        );
                  })
                }
                `}
                  key={`${new Date().getMilliseconds()}-${Math.random()}`}
                >
                  <div className="fx-container">
                    <div className="fx-layout">
                      {panel &&
                        panel.items.map((item: any) => {
                          const templates = item.data.templates;

                          if (
                            templates.find((template: any) => template.template && template.template.uid === 'faqs')
                          ) {
                            return (
                              <div
                                key={`${new Date().getMilliseconds()}-${Math.random()}`}
                                className="fx-accordion fx-layout-col fx-layout-col--mobile-24"
                              >
                                <button
                                  className={`fx-accordion-toggle fx-accordion-toggle--has-gutters ${
                                    this.state.accordionToggleIndex === item.id ? 'fx-accordion-toggle--is-visible' : ''
                                  }`}
                                  type="button"
                                  data-id={item.id}
                                  onClick={this.toggleAccordion}
                                >
                                  {item.data.title.text} <i className="fx-accordion-toggle-icon las la-angle-down" />
                                </button>
                                <div
                                  className={`fx-accordion-content fx-l-rich-text fx-accordion-content--has-gutters ${
                                    this.state.accordionToggleIndex === item.id
                                      ? 'fx-accordion-content--is-visible'
                                      : ''
                                  }`}
                                  dangerouslySetInnerHTML={{ __html: item.data && item.data.body.html }}
                                />
                              </div>
                            );
                          } else {
                            return (
                              <div
                                className={
                                  this.state.collectionLayout
                                    ? 'fx-layout-col-mobile--24 fx-layout-col-tablet--8'
                                    : 'fx-layout-col-mobile--24'
                                }
                                key={`${new Date().getMilliseconds()}-${Math.random()}`}
                              >
                                <section
                                  className={`fx-l-section fx-l-section--collection fx-l-section--${templates
                                    .map((template: any) => `${template.template && template.template.uid}`)
                                    .join()
                                    .replace(',', ' ')}`}
                                  key={`${new Date().getMilliseconds()}-${Math.random()}`}
                                >
                                  {item.data.images.find((image: any) => image.image.url) &&
                                    item.data.images.map((image: any) => (
                                      <img
                                        className="fx-l-section-media"
                                        key={`image--${new Date().getMilliseconds()}-${Math.random()}`}
                                        src={image.image.url}
                                        alt={image.image.alt}
                                      />
                                    ))}
                                  <h3 className="fx-l-section-title">{item.data.title.text}</h3>
                                  <div
                                    className="fx-l-section-subtitle"
                                    dangerouslySetInnerHTML={{ __html: item.data.subtitle && item.data.subtitle.html }}
                                  />
                                  <p className="fx-l-section-body">{item.data.body.text}</p>

                                  <CTAButton data={item.data} />
                                </section>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </FxLayout>
    );
  }
}
